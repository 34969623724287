/* Component Dependencies */
var PubSub = require('libs/pub-sub');
var searchForm = require('../../libs/searchFormHorizontal-lib');

var searchFormHorizontalHomePageTemplate = require('templates/searchFormHorizontalHomePage.hbs');
var searchFormHorizontalHomePageTemplateV2 = require('templates/searchFormHorizontalHomePageV2.hbs');

PubSub.register(searchForm.extend({  
  type: 'searchFormHorizontalHomePage',
  template: {
    'searchFormHorizontalHomePage': searchFormHorizontalHomePageTemplate,
    'searchFormHorizontalHomePageV2': searchFormHorizontalHomePageTemplateV2
  }
}));
